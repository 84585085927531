import React from "react";
import { CartItem } from "./cart-item";

export const Cart = ({ state, dispatch }) => {
  const handleClear = () => {
    dispatch({type: "cart-clear"})
  };

  return (
    <div className="col">
      <h3>Cart</h3>
      <form action="/create-checkout-session" method="POST">
        <ul className="list-group">
          {state.items.filter(item => item.selected).map((item) => (
            <CartItem key={item.price_id} {...item} dispatch={dispatch} />
          ))}
        </ul>
        <div className="mt-2">
          <label htmlFor={"-email"} className="form-label" style={{ color: "white" }}>
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id={"-email"}
            name="email"
            placeholder="someone@email.com"
            required
          />
        </div>
        <div className="btn-group d-flex mt-2">
          <button onClick={handleClear} className="btn btn-secondary" type="button">Clear</button>
          <button className="btn btn-purple" type="submit">Checkout</button>
        </div>
      </form>
    </div>
  );
};
