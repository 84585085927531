import React, { MouseEventHandler, useEffect, useRef } from "react";
import dialogPolyfill from "dialog-polyfill";
import { getFlag } from "../flags";

const Product = ({ image, image_desc, name, price, onChange, onIncrement, onDecrement, quantity }) => {
  const handleChange = (event) => {
    const value = event.target.value ?? "";
    const parsed = parseInt(value.replace(/\D*/gi, "").trim());
    onChange(parsed);
  };

  const increment = () => {
    onIncrement();
  };

  const decrement = () => {
    onDecrement();
  };

  const getInputWidth = () => {
    return `${quantity.toString().length + 4}ch`;
  };

  return (
    <div className="product">
      <img className="product--img" src={image} alt={image_desc} />
      <div className="product--description">
        <h3 className="product--description-text">{name}</h3>
        <h5 className="product--description-price">{price}</h5>
      </div>
      <div className="product--quantity">
        <div className="input-group">
          <button className="btn btn-outline-secondary" type="button" onClick={decrement}>
            -
          </button>
          <input
            style={{ width: getInputWidth() }}
            className="form-control"
            type="text"
            min="1"
            max="10"
            value={quantity}
            onChange={handleChange}
          />
          <button className="btn btn-outline-secondary" type="button" onClick={increment}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export const ProductCard = ({ name, price, image, image_desc, price_id, quantity, dispatch }) => {
  const dialogRef = useRef<HTMLDialogElement>();

  useEffect(() => {
    dialogPolyfill.registerDialog(dialogRef.current);
  }, [dialogRef]);

  const handleCancel = () => {
    dialogRef.current.close();
  };

  const handleCheckout: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dialogRef.current.showModal();
  };

  const handleChange = (value) => {
    dispatch({ type: "set-quantity", data: { price_id, quantity: value } });
  };

  const increment = () => {
    dispatch({ type: "increment", data: { price_id } });
  };

  const decrement = () => {
    dispatch({ type: "decrement", data: { price_id } });
  };

  const handleAdd = () => {
    dispatch({ type: "cart-add", data: { price_id } });
  };

  return (
    <div className="row mb-4">
      <section className="col product-card">
        <Product
          image={image}
          image_desc={image_desc}
          name={name}
          price={price}
          onChange={handleChange}
          onDecrement={decrement}
          onIncrement={increment}
          quantity={quantity}
        />
        {getFlag("enable-cart") ? (
          <div className="product-card--actions">
            <button onClick={handleAdd} className="btn-purple checkout-button" type="button">
              Add
            </button>
            <button onClick={handleCheckout} className="btn-purple checkout-button" type="button">
              Buy
            </button>
          </div>
        ) : (
          <button onClick={handleCheckout} className="btn-purple checkout-button" type="button">
            Select
          </button>
        )}
        <dialog className="checkout-dialog" ref={dialogRef}>
          <h4>Selected</h4>
          <Product
            image={image}
            image_desc={image_desc}
            name={name}
            price={price}
            onChange={handleChange}
            onDecrement={decrement}
            onIncrement={increment}
            quantity={quantity}
          />
          <form action="/create-checkout-session" method="POST">
            <div className="mb-3">
              <label htmlFor={price_id + "-email"} className="form-label">
                Email address, so we know who to deliver the tokens to
              </label>
              <input
                id={price_id + "-email"}
                type="email"
                className="form-control"
                name="email"
                placeholder="someone@email.com"
                required
              />
            </div>
            <input type="hidden" name="priceId" value={price_id} />
            <input type="hidden" name="qty" value={quantity} />
            <button onClick={handleCancel} className="btn btn-ghost" type="button">
              Cancel
            </button>
            <button className="btn btn-purple" type="submit">
              Buy Now
            </button>
          </form>
        </dialog>
      </section>
    </div>
  );
};
