import React, { useReducer } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./checkout.css";
import { Cart } from "./components/cart";
import { getFlag } from "./flags";
import { getPreload } from "./preload";
import { ProductList } from "./products";

Sentry.init({
  dsn: "https://f208fe4b84634de48b0ff72a0ef91b8e@o4504821111128064.ingest.sentry.io/4504821221359616",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = createRoot(document.querySelector("#root") as HTMLElement);

const getItems = (preload) => {
  const items = preload.items ?? [];
  return items.map((item) => ({ ...item, quantity: 1, selected: false }));
};

const initialState = { items: getItems(getPreload()) };

const clamp = (value: number) => {
  if (!value || value <= 1 || isNaN(value)) {
    return 1;
  }

  if (value >= 10) {
    return 10;
  }
  return value;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "increment": {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.price_id === action.data.price_id) {
            return { ...item, quantity: clamp(item.quantity + 1) };
          }
          return item;
        }),
      };
    }
    case "decrement": {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.price_id === action.data.price_id) {
            return { ...item, quantity: clamp(item.quantity - 1) };
          }
          return item;
        }),
      };
    }
    case "set-quantity": {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.price_id === action.data.price_id) {
            return { ...item, quantity: clamp(action.data.quantity) };
          }
          return item;
        }),
      };
    }
    case "cart-add": {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.price_id === action.data.price_id) {
            return {...item, selected: true}
          }
          return item;
        })
      }
    }
    case "cart-remove": {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.price_id === action.data.price_id) {
            return {...item, selected: false}
          }
          return item;
        })
      }
    }
    case "cart-clear": {
      return {
        ...state,
        items: state.items.map(item => {
            return {...item, selected: false}
        })
      }
    }
    default:
      throw new Error("unknown action");
  }
};

const Root = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showCart = getFlag("enable-cart") && state.items.some(item => item.selected);

  return (
    <div className="container checkout-root">
      <header className="text-center mt-4 mb-5">
        <h1 className="checkout-title">Pixel Paradise - Buy Tokens</h1>
      </header>
      <main className="row justify-content-md-center">
        <div className={`col-12 col-md-6 ${showCart ? "offset-md-0 offset-lg-1 offset-xl-2 offset-xxl-3" : ""}`}>
          <ProductList state={state} dispatch={dispatch} />
        </div>
        {showCart ? <Cart state={state} dispatch={dispatch} /> : null}
      </main>
      <p className="notice">Tokens are redeemed in store using a QR Code recieved after purchase</p>
    </div>
  );
};

root.render(<Root />);
