import React from "react";

export const CartItem = ({ name, price_id, quantity, dispatch }) => {
  const handleChange = (value) => {
    dispatch({ type: "set-quantity", data: { price_id, quantity: value } });
  };

  const increment = () => {
    dispatch({ type: "increment", data: { price_id } });
  };

  const decrement = () => {
    dispatch({ type: "decrement", data: { price_id } });
  };

  const handleRemove = () => {
    dispatch({ type: "cart-remove", data: { price_id } });

  }

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <span>{name}</span>
      <input type="hidden" name="priceId" value={price_id} />
      <div className="product--quantity">
        <div className="input-group">
          <button className="btn btn-outline-secondary" type="button" onClick={decrement}>
            -
          </button>
          <input
            style={{ width: "5ch" }}
            className="form-control"
            type="text"
            min="1"
            max="10"
            name="qty"
            value={quantity}
            onChange={handleChange}
          />
          <button className="btn btn-outline-secondary" type="button" onClick={increment}>
            +
          </button>
        </div>
      </div>
      <button onClick={handleRemove} type="button" className="btn btn-outline-danger">🗑️</button>
    </li>
  );
};
